<template>
    <div class="code-fun">
        <div class="list" v-for="(item,index) in List" :key="index">
            <div class="box">
                <div class="img">
                    <img :src="item.image" />
                </div>
                <div  class="name">
                    <div>{{item.store_name}}</div>
                </div>
                <div class="laji" @click="del(index)">
                    <span class="iconfont iconlajixiang"></span>
                </div>
            </div>
        </div>
        <div class="mao-box">
            <div class="felx">
                <div class="saoma ma" @click="scanCode">
                    <span class="iconfont iconsaoma"></span>
                    <span>扫码</span>
                </div>
                <!-- <div class="line"></div> -->
                <div class="saoma businessma" @click="productruku">
                    <span class="iconfont icondaochu"></span>
                    <span>绑定商家({{List.length?List.length:0}})</span>
                </div>
            </div>
            <div class="foot-heigt"></div>
        </div>
        <Popup  v-model="zhidingwin" round="true"  :close-on-click-overlay="true" :close-on-popstate="true" closeable>
            <div>
                <div class="list-box">
                    <div class="box">
                        <div v-for="(item,index) in zhidinglist" :key="index">{{item.code}}({{item.val}})</div>
                    </div>
                </div>
            </div>
        </Popup>
    </div>
</template>
<script>

import { wechatEvevt } from "@libs/wechat";
import {Toast,Popup } from 'vant';
import { productpcode, productpmcode, productruku} from '../../../api/store.js';
export default {
    name: "codeFun",
    components: {
        Toast,Popup
    },
    props: {},
    data: function() {
        return {
            //number:0,
            List:[],
            zhidingwin: false,
            zhidinglist: [],
        };
    },
    created: function() {

    },
    mounted: function() {
        this.initcode();
    },
    methods: {
       initcode(){
           this.scanCode();
       },
       //扫码集合方法
       scanCode(){
           if(this.List.length >= 30){
               this.$dialog.error('一次最多可以添加30个产品~');
            }else{
                this.saoma();
            }
       },
       //箱码
        productpcode: function (code) {
            var that = this;
            productpcode({
                xunique: code,
            }).then(res=>{
                var listdata = res.data.data || [];
                listdata.forEach(function(item, index){
                    that.List.forEach(function(sonitem, sonindex){
                        if(item.unique1==sonitem.unique1){
                            that.List.splice(sonindex, 1);
                        }
                    });
                    that.List.push(item);
                })
                //that.List=that.List;
            }).catch(err=>{
                that.$dialog.error(err.msg);return
            });
        },
        //瓶码
        productpmcode: function (code) {
            var that = this;
            productpmcode({
                unique1: code,
            }).then(res=>{
            var listdata = res.data.data || [];
            listdata.forEach(function(item, index){
                that.List.forEach(function(sonitem, sonindex){
                if(item.unique1==sonitem.unique1){
                    that.List.splice(sonindex, 1);
                }
                });
                that.List.push(item);
            })
                //this.List=that.List;
            }).catch(err=>{
                that.$dialog.error(err.msg);return
            });
        },
        productruku: function () {
            var that = this;
            var goods = [];
            that.List.forEach(function(item){
                goods.push(item.unique1);
            })
            //if(this.data.type == 1){
                Toast.loading({
                    duration: 0,
                    message: '正在入库',
                    forbidClick: true,
                });
                productruku({
                    goods: goods,
                }).then(res=>{
                    Toast.clear();
                    that.List=[];
                    that.zhidingwin=!that.zhidingwin;
                    that.zhidinglist= res.data.data;
                }).catch(err=>{
                    Toast.clear();
                    this.$dialog.error(err.msg);
                });
            //}else{
            // wx.showLoading();
            // productfxruku({
            //     goods: goods,
            // }).then(res=>{
            //     wx.hideLoading();
            //     that.setData({
            //     List: [],
            //     zhidingwin: !this.data.zhidingwin,
            //     zhidinglist: res.data.data,
            //     })
            // }).catch(msg=>{
            //     wx.hideLoading();
            //     return app.Tips({ title: msg });
            // });
            //}
            
       },
       saoma(){
            var that = this;
            var config = {
                needResult:1,// 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode"],// 可以指定扫二维码还是一维码，默认二者都有
            }
            wechatEvevt(
              ['scanQRCode'],config
            ).then(res => {//res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                console.log(res);
                if(res.resultStr.indexOf("&code=") != -1){
                    var Arry = res.resultStr.split('&code=');
                    if(that.List.length > 24){
                        this.$dialog.error('一次最多可以添加30个产品~');
                    }else{
                        that.productpcode(Arry[1]);
                    }
                }else if(res.resultStr.indexOf("&pcode=") != -1){//瓶码
                    var Arry = res.resultStr.split('&pcode=');
                    that.productpmcode(Arry[1]);
                }else{
                    that.$dialog.error('该商品二维码不存在~');
                }
                //alert(res.resultStr.scan_code);

              }
            ).catch(err => {
                console.log(err.msg);
              });
        },
        del(index){
            this.List.splice(index,1);
        }
    }
};
</script>
<style lang="scss">
.code-fun{
    .list{
        .box{
            display: flex;
            padding: 0.2rem 0;
            border-bottom:1px solid #eee;
            background: #fff;
            align-items: center;
            justify-content: center;
            .img{
                width: 1rem;
                height: 1rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .name{
                padding-left:0.1rem; 
                width: 75%;
            }
            .laji{
                
                .iconfont{
                    color: #999;
                    font-size: 0.4rem;
                }
            }
        }
    }
    .mao-box{
        position:fixed;
        bottom: 0;
        width: 100%;
        background: #fff;
        font-size: 0.3rem;
        .felx{
             display: flex;
        }
        .saoma{
            text-align: center;
            margin: 10px auto;
            width: 50%;
            .iconfont{
                 font-size: 0.3rem;
            }
        }
        // .line{
        //     border: 1px solid #000;
        // }
    }
    .list-box{
        width: 5rem;
        height: 5rem;
        text-align: center;
        padding: 0.4rem 0;
        overflow: hidden;
        .box{
            height: 4.2rem;
            overflow-y: auto;
        }
    }
    .foot-heigt{
        width: 100%;
        height: 0.3rem;
    }
    
}

</style>